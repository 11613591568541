import * as React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { LangProvider } from '../context/lang/langContext';
import BannerPrincipal from '../components/BannerPrincipal';
import Tecnologia from '../components/Tecnologia';
import ComoTrabajamos from '../components/ComoTrabajamos';
import QuienesSomos from '../components/QuienesSomos';

const IndexPage = () => (
    <div style={{
        marginTop: -90, '-webkit-filter': 'grayscale(100%)', '-moz-filter': 'grayscale(100%)', filter: 'grayscale(100%)',
    }}
    >
        <LangProvider>
            <Layout>
                <Header />
                <BannerPrincipal />
                <Tecnologia />
                <ComoTrabajamos />
                <QuienesSomos />
                <Footer />
            </Layout>
        </LangProvider>
    </div>

);

export default IndexPage;
